<template>
  <b-row>
    <b-col md="12">
      <b-card no-body>
        <b-card-body>
          <b-row>
            <b-col>
              <b-input-group>
                <b-form-input
                  v-model="search"
                  placeholder="Plaka, Müşteri Adı, Firma Adı, Telefon..."
                  @keydown.enter="keywordSearch"
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    @click="keywordSearch"
                  >
                    <FeatherIcon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col
              cols="auto"
            >
              <b-button
                to="cars/search"
                variant="primary"
              >
                <FeatherIcon icon="PlusIcon" />Oluştur
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
        <b-table
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
          fixed
        >
          <template #cell(customer)="data">
            {{ data.item.customer }}
            <div v-if="data.item.company_name">
              <small class="text-primary">{{ data.item.company_name }}</small>
            </div>
          </template>
          <template #cell(swap_brand)="data">
            <div v-if="data.item.swap_brand">
              {{ data.item.swap_brand }} {{ data.item.swap_model }}
            </div>
            <div v-else>
              {{ data.item.brand }} {{ data.item.model }}
            </div>
            <div v-if="data.item.hardware">
              <small class="text-primary">
                {{ data.item.hardware }}
              </small>
            </div>
            <div v-if="data.item.myear">
              <small class="text-warning">
                {{ data.item.myear }}
              </small>
            </div>
          </template>
          <template #cell(control)="data">
            <b-button
              :to="'/cars/edit/' + data.item.id"
              variant="outline-primary"
              size="sm"
            >
              Güncelle
            </b-button>
          </template>
        </b-table>
        <b-card-footer><b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination></b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BTable, BCardFooter, BPagination, BButton, BInputGroup, BInputGroupAppend, BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardBody,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      search: '',
      fields: [
        {
          key: 'customer',
          label: 'Müşteri',
        },
        {
          key: 'swap_brand',
          label: 'Araç',
        },
        {
          key: 'license_plate',
          label: 'Plaka',
          thStyle: { width: '200px' },
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '140px' },
        },
      ],
      dataQuery: {
        select: [
          'com_cars.id AS id',
          'com_customer.name AS customer',
          'com_customer.company_name AS company_name',
          'com_swap_brand.name AS swap_brand',
          'com_swap_model.title AS swap_model',
          'com_brand.name AS brand',
          'com_model.title AS model',
          'com_cars.hardware AS hardware',
          'com_cars.license_plate AS license_plate',
          'com_cars.myear AS myear',
          'com_cars.id_com_swap_brand AS id_com_swap_brand',
          'com_cars.id_com_swap_model AS id_com_swap_model',
        ],
        limit: 25,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['cars/getCars']
    },
    dataCount() {
      return this.$store.getters['cars/getCarsCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 25
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    keywordSearch() {
      this.dataQuery.or_like = {
        'com_customer.company_name': this.search,
        'com_customer.name': this.search,
        'com_customer.phone': this.search,
        'com_cars.license_plate': this.search,
      }
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('cars/carsList', this.dataQuery)
    },
  },
}
</script>

<style scoped>

</style>
